import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Grid from '@material-ui/core/Grid';

import Link from '~components/Link';
import {
  LabelNew,
  LabelAudio,
  LabelPremium,
  LabelProducer,
  LabelOverlay
} from '~components/playlist-card/PlaylistLabels';

import { boolFlagFromString, formatDurationOfFirstVideoTishaBav } from '~utils/playlist-helpers';
import Excerpt from '~components/Excerpt';
import { lineFix } from '~utils/popup-helpers';

const VideoTitle = styled.h6`
  display: flex;
  font-size: 22px;
  align-items: center;
  font-weight: 500;
  margin: 0;
  line-height: 24px;
  letter-spacing: 0.00938em;
  margin-bottom: 6px;
`;

const StyledImage = styled.img`
  display: flex;
  max-width: 100%;
`;

const InfoText = styled.p`
  font-size: 0.875rem;
  line-height: 1.57;
  letter-spacing: 0.00714em;
  display: flex;
  align-items: center;
  font-weight: normal;
  margin: 0;
  @media only screen and (max-width: 322px) {
    display: block;
  }
`;

const StyledLabelsOverlay = styled(LabelOverlay)`
  position: unset;
  margin-left: -4px;
  & > * {
    margin-left: 4px;
  }
`;

const StyledExcerpt = styled(Excerpt)`
  font-size: 16px !important;
  word-break: break-word;
  line-height: 19px !important;
  margin-block-end: 15px !important;
  margin-block-start: 6px !important;
`;

const VideoCard = ({
  playlist: {
    url,
    title,
    type,
    subtitle,
    description,
    cover_image,
    author,
    label_is_new,
    label_is_audio,
    label_is_premium,
    label_is_producer,
    videos
  }
}) => {
  const isAudio = boolFlagFromString(label_is_audio);
  const isNew = boolFlagFromString(label_is_new);
  const isPremium = boolFlagFromString(label_is_premium);
  const isProducer = boolFlagFromString(label_is_producer);

  const infoText = useMemo(() => {
    if (type === 'podcast_episode') {
      return subtitle;
    }
    const durationText = `${formatDurationOfFirstVideoTishaBav(
      videos[0]?.length_in_seconds
    )} video`;
    const episodeText = videos.length > 1 ? <>Part 1 of {videos.length}</> : null;
    return [author, episodeText, durationText].filter(Boolean).join(' • ');
  }, [author, type, subtitle, videos]);

  return (
    <>
      <Grid container direction="row" justify="flex-start" alignItems="flex-start" spacing={3}>
        <Grid item md={3}>
          <Link to={url} underline="hover" color="textPrimary" style={{ width: '100%' }}>
            <StyledImage src={cover_image.url} alt={cover_image.alt} className="lazyload" />
          </Link>
        </Grid>
        <Grid item md={8}>
          <VideoTitle>
            <Link to={url} underline="hover" color="textPrimary">
              {lineFix(title)}
            </Link>
          </VideoTitle>

          <InfoText>{infoText}</InfoText>

          <StyledExcerpt description={description.text} buttons={false} />

          <StyledLabelsOverlay>
            {isNew && <LabelNew />}
            {isAudio && <LabelAudio />}
            {isPremium && <LabelPremium />}
            {isProducer && <LabelProducer />}
          </StyledLabelsOverlay>
        </Grid>
      </Grid>
    </>
  );
};
VideoCard.propTypes = {
  playlist: PropTypes.shape({
    author: PropTypes.string.isRequired,
    url: PropTypes.string,
    title: PropTypes.string,
    id: PropTypes.string,
    cover_image: PropTypes.shape({
      url: PropTypes.string.isRequired,
      alt: PropTypes.string
    }),
    description: PropTypes.shape({
      text: PropTypes.string,
      html: PropTypes.string
    }),
    type: PropTypes.oneOf(['playlist', 'podcast_episode']),
    subtitle: PropTypes.string,
    label_is_new: PropTypes.string,
    label_is_audio: PropTypes.string,
    label_is_premium: PropTypes.string,
    label_is_producer: PropTypes.string,
    videos: PropTypes.arrayOf(
      PropTypes.shape({
        length_in_seconds: PropTypes.number
      })
    ).isRequired
  })
};
VideoCard.defaultProps = {
  playlist: {}
};

export default VideoCard;
